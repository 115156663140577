import React, { useState } from 'react';
import './CssStyles/CompanyProfile.css';

function CompanyProfile() {
    const [selectedService, setSelectedService] = useState(0); // Default to the first service
    const [animationClass, setAnimationClass] = useState('enter');

    const profile = [
        {
            name: "Our Mission",
            content: "At ByteBlock Technologies, our mission is to empower businesses by delivering custom, scalable software solutions that act as the essential building blocks of digital success. We simplify complex challenges with secure, intuitive technology, creating solutions that form the foundation for long-term growth. Our commitment is to foster strong partnerships and help our clients thrive in an ever-evolving digital world."
        },
        {
            name: "Our Vision",
            content: "We envision a future where businesses of all sizes can unlock their full potential by leveraging technology as the core building block of success. At ByteBlock, we strive to be the leading technology partner in digital transformation, providing innovative solutions that set the stage for sustainable growth and adaptability. Our goal is to help businesses lay the groundwork for a competitive edge and future-proof their operations."
        },
        {
            name: "Our Founders Philosophy",
            content: (
                <>
                    <blockquote>
                        "We will deliver Technology Solutions at the speed of life by observing our ever-changing environment & keep listening to all our stakeholders whilst keeping humanities at the core of everything we do."
                        <span className="quote-author">- Mesam A Rizvi, Chairman & Co-Founder</span>
                    </blockquote>
                    <blockquote>
                        "Our clients are at the heart of everything we do. My philosophy is simple: build relationships, deliver excellence, and let success be a shared journey."
                        <span className="quote-author">- Mehzar M Rizvi, CEO & Founder</span>
                    </blockquote>
                </>
            )
        },
        {
            name: "Clients",
            content: (
                <div className="clients-logos">
                    <img src="/images/clients/gagangiri.png" alt="Client 1" className="client-logo" />
                </div>
            )
        }
    ];

    const handleServiceClick = (index) => {
        setAnimationClass('exit');
        setTimeout(() => {
            setSelectedService(index);
            setAnimationClass('enter');
        }, 500); // Match the duration of the CSS transition
    };

    return (
        <div className='company-profile-div'>
            <div className='profile profile3'>
                <div className={`profile-content ${animationClass}`}>
                    <h2 className='profile-name'>{profile[selectedService].name}</h2>
                    <div className='profile-description'>{profile[selectedService].content}</div>
                </div>
            </div>
            <div className='profile profile2'>
                <ul className='profile-list'>
                    {profile.map((profile, index) => (
                        <li
                            key={index}
                            className={selectedService === index ? 'selected' : ''}
                            onClick={() => handleServiceClick(index)}
                        >
                            {profile.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className='profile profile1'> 
                <h1 className='rotated-text-company'>Company Profile</h1>
            </div>
        </div>
    );
}

export default CompanyProfile;