import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar';
import LandingPage from './Components/LandingPage';
import Services from './Components/Services';
import CompanyProfile from './Components/CompanyProfile';
import ServicePage from './Components/ServicePage';
import ContactUs from './Components/ContactUs';
import ScrollToTop from './Components/ScrollToTop.js';
import Footer from './Components/Footer';
import ExecutiveProfile from './Components/ExecutiveProfile';
import NotYet from './Components/NotYet.js';
import CaseStudies from './Components/CaseStudies.js';
import CaseStudyDetails from './Components/CaseStudyDetails.js';
import './App.css';

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <ScrollToTop />
        <ScrollToSection />
        <Routes>
          <Route path="/" element={
            <>
              <div id="landing-page">
                <LandingPage />
              </div>
              <div id="services-profile">
                <Services />
              </div>
              <div id="company-profile">
                <CompanyProfile />
              </div>
              <div id="contact-us-app">
                <ContactUs />
              </div>
              <Footer />
            </>
          } />
          <Route path='/executive-profile' element={<ExecutiveProfile />} />
          <Route path="/service/:id" element={<ServicePage />} />
          <Route path="/products" element={<NotYet />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-study/:id" element={<CaseStudyDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
