// CaseStudies.js
import React, { useEffect, useState } from 'react';
import CaseStudyCard from './CaseStudyCard';
import CaseStudyData from './CaseStudies.json';
import './CssStyles/CaseStudies.css'; // Import the CSS file for styling

const CaseStudies = () => {
    const [caseStudies, setCaseStudies] = useState([]);

    useEffect(() => {
        setCaseStudies(CaseStudyData);
    }, []);

    return (
        <div className="case-studies-container">
            {caseStudies.map(caseStudy => (
                <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
            ))}
        </div>
    );
};

export default CaseStudies;