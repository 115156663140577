import React from 'react';
import './CssStyles/LandingPage.css';

function LandingPage() {
    return (
        <div className='landing-page'>
            <div className='text-container'>
                <h1 className='landingpage-title-text'>
                    Explore the future of <span className='highlight'>technology</span>,<br />
                    one <span className='highlight'>block</span> at a time.
                </h1>
                <p className='landing-page-content-text'>
                    ByteBlock Technologies builds your digital future block by block with tailored, cutting-edge solutions that efficiently meet diverse needs without compromising on style.
                </p>
            </div>
            <div className='content'>
                <div className='left-div'></div>
                <div className='right-div'></div>
            </div>
        </div>
    )
}

export default LandingPage;