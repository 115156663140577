import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import servicesData from './ServicesInfo.json';
import ServiceCard from './ServiceCard';
import Footer from './Footer';
import './CssStyles/ServicePage.css';

function ServicePage() {
    const { id } = useParams();
    const [service, setService] = useState(null);

    useEffect(() => {
        const serviceInfo = servicesData.find(service => service.id === parseInt(id));
        setService(serviceInfo);
        console.log(serviceInfo);
    }, [id]);

    if (!service) {
        return <div>Loading...</div>;
    }

    return (
        <div className='service-page'>
            <div className='service-container'>
                <img src={service['service-main-image']} alt={service.service} className='service-image' />
                <div className='service-info'>
                    <h1>{service.service}</h1>
                    <p>{service.description}</p>
                </div>
            </div>
            <div className='extra-services-container'>
                {service['extra-services'].map((extraService, index) => (
                    <ServiceCard key={index} service={extraService} />
                ))}
            </div>
            <Footer />
        </div>
    );
}

export default ServicePage;