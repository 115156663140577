import React, { useEffect } from 'react';
import Footer from './Footer';
import './CssStyles/ExecutiveProfile.css';

function ExecutiveProfile() {
    return (
        <div className='executive-profile-div'>
            <h1 className='exec-title'>Executive Profile</h1>
            <div className='outer-div'>
                <div className='inner-div'>
                    <img src='/images/executiveprofile/rizvimesam.jpg' alt='Executive' className='inner-image' />
                    <div className='text-div'>
                        <p className='name-text'>Mesam Akhtar Rizvi</p>
                        <p className='position-text'>Chairman & Co-founder</p>
                    </div>
                </div>
                <div className='side-div'>
                    <p className='side-text'>
                        Mesam Akhtar Rizvi brings over two decades of experience in leadership role, with successfully running a multimillion-dollar Tech Business. With an entrepreneurial alert personality, he has a heightened sense of autonomy, openness to experience, and self-reliance.
                    </p>
                    <p className='side-text'>
                        As ByteBlock’s Chairman & Co-Founder, his leadership will help the company interplay between risk tolerance, self-efficacy, the need for achievement and innovativeness. He will leverage his extensive global experience, especially Africa & Middle-East, to drive the company’s expansion strategies and technological innovations.
                    </p>
                    <p className='side-text'>
                        Mesam’s academic background includes a Master's degree in Marketing Management and Bachelor of Engineering (B.E) with Honors, specializing in Electronics, both from the highly reputed Bombay University.
                    </p>
                    <p className='side-text'>
                        Outside of work, Mesam enjoys traveling and also continually expands his knowledge through management literature. He is a devout family man and strongly believes in work-life balance. He also aggressively pursues his philanthropic passion, and is a strong believer that effective-philanthropy is an obligation but that it should necessarily be guided by a measured-output as he believes that the gift itself does not constitute a moral act, only its results.
                    </p>
                </div>
            </div>
            <div className='outer-div'>
                <div className='side-div'>
                    <p className='side-text'>
                        Mehzar Mesam Rizvi is the CEO and Founder of ByteBlock Technologies, bringing fresh perspectives and cutting-edge technological expertise to an ever-evolving digital landscape. With a BSc (Honours) in Computer Science from the prestigious University of St Andrews, Scotland, Mehzar embodies his core philosophy: "Our clients are at the heart of everything we do. My philosophy is simple: build relationships, deliver excellence, and let success be a shared journey."
                    </p>
                    <p className='side-text'>
                        Specializing in various software technologies, Mehzar leads ByteBlock Technologies with a unique blend of technical acumen and strategic vision. Despite being a recent graduate, he has quickly established himself as a rising force in the tech industry, bridging the gap between cutting-edge academic research and real-world applications.
                    </p>
                    <p className='side-text'>
                        As the driving force behind ByteBlock Technologies, Mehzar's commitment to excellence extends beyond technical expertise. He actively fosters an environment where innovation thrives, ensuring each client receives personalized, high-quality solutions. When not revolutionizing the tech landscape, he pursues his passion for emerging technologies and global perspectives, continuously seeking ways to enhance ByteBlock Technologies' service offerings and drive client success.
                    </p>
                    <p className='side-text'>
                        His unique combination of technical expertise, leadership qualities, and client-centric approach positions ByteBlock Technologies at the forefront of the digital revolution, ready to tackle the challenges of tomorrow's tech landscape.
                    </p>
                </div>
                <div className='inner-div'>
                    <img src='/images/executiveprofile/mehzarProfileGreybg2.jpeg' alt='Executive' className='inner-image-2' />
                    <div className='text-div-2'>
                        <p className='name-text'>Mehzar Mesam Rizvi</p>
                        <p className='position-text'>CEO & Founder</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ExecutiveProfile;