import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './CssStyles/Footer.css';

function Footer() {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className='footer'>
            <div className='footer-complete'>
                <div className='footer-left'>
                    <img src='/images/bytefooter.png' alt='Company Logo' className='footer-logo' />
                </div>
                <div className='footer-right'>
                    <div className='footer-column'>
                        <h3>Quick Links</h3>
                        <ul>
                            <Link to='/'><li>Home</li></Link>
                            <Link to="/#company-profile" onClick={() => handleNavigation('/#company-profile')}><li>Company Profile</li></Link>
                            <Link to='/executive-profile'><li>Executive Profile</li></Link>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3>Services & Products</h3>
                        <ul>
                            <Link to="/#services-profile" onClick={() => handleNavigation('/#services-profile')}><li>Services & Products Overview</li></Link>
                            <Link to="/service/1"><li>Custom Software Development</li></Link>
                            <Link to='/service/2'><li>API Integration & Development</li></Link>
                            <Link to='/service/3'><li>Advanced Data Analytics & Visulatization</li></Link>
                            <Link to='/service/4'><li>Legacy System Transformation</li></Link>
                            <Link to='/service/5'><li>Web Design & Developement</li></Link>
                            <Link to='/service/6'><li>Technology Consultancy</li></Link>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3>Connect</h3>
                        <ul>
                            <li>104, Aashna, Plot 13 16, St. Martin Road, Bandra West, Mumbai 400 050, India.</li>
                            <li><a href="tel:+918291215390">+91 8291215390</a></li>
                            <li><a href="mailto:inquiry@byte-block.com">inquiry@byte-block.com</a></li>
                        </ul>
                        <div className='footer-social-icons'>
                            <a href="https://www.facebook.com/byteblocktechnologies/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://x.com/byteblocktech" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                            <a href="https://www.instagram.com/byteblocktechnologies/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://linkedin.com/company/byteblock-technologies" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;