import React from 'react';
import { Link } from 'react-router-dom';
import './CssStyles/CaseStudyCard.css'; // Import the CSS file for styling

const CaseStudyCard = ({ caseStudy }) => {
    return (
        <div className="case-study-card">
            <img src={caseStudy.clientLogo} alt={caseStudy.client} className="case-study-logo" />
            <div className="case-study-content">
                <h3>{caseStudy.title}</h3>
                <Link to={`/case-study/${caseStudy.id}`} className="case-study-link">
                    <button className="case-study-button">Read More</button>
                </Link>
            </div>
        </div>
    );
};

export default CaseStudyCard;