import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import './CssStyles/ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = () => {
        const { firstName, lastName, email, phoneNumber, message } = formData;

        if (!firstName && !lastName && !email && !phoneNumber && !message) {
            toast.error('All fields are required!');
            return;
        }

        if (!firstName) {
            toast.error('First Name is required!');
            return;
        }

        if (!lastName) {
            toast.error('Last Name is required!');
            return;
        }

        if (!email) {
            toast.error('Email is required!');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Invalid email format!');
            return;
        }

        if (!phoneNumber) {
            toast.error('Phone Number is required!');
            return;
        }

        const phoneRegex = /^[0-9]+$/;
        if (!phoneRegex.test(phoneNumber)) {
            toast.error('Phone Number must contain only numbers!');
            return;
        }

        if (!message) {
            toast.error('Message is required!');
            return;
        }

        const templateParams = {
            from_name: `${firstName} ${lastName}`,
            to_name: 'ByteBlock',
            from_email: email,
            phone_number: phoneNumber,
            message: message,
        }

        // // Send email using EmailJS
        emailjs.send(process.env.REACT_APP_MAIL_JS_SERVICE_ID, process.env.REACT_APP_MAIL_JS_TEMPLATE_ID, templateParams, process.env.REACT_APP_MAIL_JS_USER_ID)
            .then((response) => {
                toast.success('Form submitted successfully!');
                console.log('SUCCESS!', response.status, response.text);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    message: ''
                });
            }, (err) => {
                toast.error('Failed to send email. Please try again later.');
                console.log('FAILED...', err);
            });
    };

    const handlePhoneKeyPress = (e) => {
        const charCode = e.charCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
            toast.error('Phone Number must contain only numbers!');
        }
    };

    return (
        <div id='contact-us' className='contact-us'>
            <ToastContainer />
            <div className='contact-div1'>
                <h1 className='contact-title'>Get in touch</h1>
            </div>
            <div className='form-container'>
                <div className='form-row'>
                    <div className='input-container'>
                        <input type='text' className='form-input' id='firstName' placeholder=' ' value={formData.firstName} onChange={handleChange} />
                        <label htmlFor='firstName' className='form-label'>First Name</label>
                    </div>
                    <div className='input-container'>
                        <input type='text' className='form-input' id='lastName' placeholder=' ' value={formData.lastName} onChange={handleChange} />
                        <label htmlFor='lastName' className='form-label'>Last Name</label>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='input-container'>
                        <input type='email' className='form-input' id='email' placeholder=' ' value={formData.email} onChange={handleChange} />
                        <label htmlFor='email' className='form-label'>Email</label>
                    </div>
                    <div className='input-container'>
                        <input type='text' className='form-input' id='phoneNumber' placeholder=' ' value={formData.phoneNumber} onChange={handleChange} onKeyPress={handlePhoneKeyPress} />
                        <label htmlFor='phoneNumber' className='form-label'>Phone Number</label>
                    </div>
                </div>
                <div className='form-row form-row-textarea'>
                    <textarea className='form-textarea' id='message' placeholder='Your Message' value={formData.message} onChange={handleChange}></textarea>
                </div>
                <div className='form-row form-row-submit'>
                    <button type='button' className='form-submit' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;