import React from 'react';
import './CssStyles/ServiceCard.css';

function ServiceCard({ service }) {

    return (
        <div className="service-card-feature">
            <img 
                className="service-image-feature" 
                src={service['service-image']} 
                alt={service['service']} 
            />
            <div className="service-details-feature">
                <h3 className="service-title-feature">{service['service']}</h3>
                <div className="service-info-feature">
                    <p className="service-description-feature">{service.description}</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;