import React from 'react';
import Footer from './Footer';
import './CssStyles/NotYet.css';

function NotYet() {
    return (
        <div className="not-yet-container">
            <h1 className="not-yet-title">Coming Soon</h1>
        </div>
    );
}

export default NotYet;