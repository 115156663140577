import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './CssStyles/Services.css';

function Services() {
    const [selectedService, setSelectedService] = useState(0); // Default to the first service
    const [animationClass, setAnimationClass] = useState('enter');
    const navigate = useNavigate();

    const services = [
        {
            id: 1,
            name: "Custom Software Development",
            content: "Building innovative, tailored software solutions designed to meet your unique business needs and scale with your growth."
        },
        {
            id: 2,
            name: "API Integration & Development",
            content: "Seamlessly connecting your systems with custom API solutions that ensure smooth operations and enhance efficiency."
        },
        {
            id: 3,
            name: "Advanced Data Analytics & Visualization",
            content: "Turning complex data into clear, actionable insights that drive smarter decisions and fuel growth."
        },
        {
            id: 4,
            name: "Legacy System Transformation",
            content: "Modernizing outdated systems to boost performance, security, and scalability without missing a block."
        },
        {
            id: 5,
            name: "Web Design & Development",
            content: "Crafting beautiful, high-performance websites that engage your audience and grow with your business."
        },
        {
            id: 6,
            name: "Technology Consultancy",
            content: "Empowering your business with expert tech guidance, tailored strategies, and hands-on support to thrive in the digital world."
        }
    ];

    const handleServiceClick = (index) => {
        setAnimationClass('exit');
        setTimeout(() => {
            setSelectedService(index);
            setAnimationClass('enter');
        }, 500); // Match the duration of the CSS transition
    };

    const handleServiceLinkClick = (id) => {
        navigate(`/service/${id}`);
    };

    return (
        <div className='services'>
            <div className='service service1'>
                <h1 className='rotated-text'>Discover our services</h1>
            </div>
            <div className='service service2'>
                <ul className='services-list'>
                    {services.map((service, index) => (
                        <li
                            key={index}
                            className={selectedService === index ? 'selected' : ''}
                            onClick={() => handleServiceClick(index)}
                        >
                            {service.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className='service service3'>
                <div className={`service-content ${animationClass}`}>
                    <label className='service-label'>Service</label>
                    <h2 className='service-name'>{services[selectedService].name}</h2>
                    <p className='service-description'>{services[selectedService].content}</p>
                    <div className='service-link' onClick={() => handleServiceLinkClick(services[selectedService].id)}>
                        <span>{services[selectedService].name}</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;