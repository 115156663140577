import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes, faExpand } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './CssStyles/NavBar.css';

function NavBar() {
    const [aboutDropdown, setAboutDropdown] = useState(false);
    const [projectsDropdown, setProjectsDropdown] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const navigate = useNavigate();

    const toggleAboutDropdown = () => setAboutDropdown(!aboutDropdown);
    const toggleProjectsDropdown = () => setProjectsDropdown(!projectsDropdown);
    const toggleMobileMenu = () => {
        if (mobileMenuOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setMobileMenuOpen(false);
                setIsClosing(false);
            }, 300); // Duration of the slideOut animation
        } else {
            setMobileMenuOpen(true);
        }
    };

    const closeMobileMenu = () => {
        handleNavigation('/#contact-us-app');
        setMobileMenuOpen(false);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMobileMenuOpen(false);
    };

    return (
        <nav className='navbar'>
            <div className='navbar-left'>
                <Link to='/'><img src='/images/ByteBlockLogoSide.png' alt='Logo' className='navbar-logo' /></Link>
            </div>
            <div className={`navbar-right ${mobileMenuOpen ? 'open' : ''}`}>
                <div className={`navbar-links ${mobileMenuOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`}>
                    <div className='nav-link' onClick={toggleAboutDropdown}>
                        ABOUT US <FontAwesomeIcon icon={faExpand} className={`angle-icon ${aboutDropdown ? 'rotate' : ''}`} />
                        <div className={`dropdown ${aboutDropdown ? 'show' : ''}`}>
                            <Link to="/#company-profile" onClick={() => handleNavigation('/#company-profile')}>Company Profile</Link>
                            <Link to='/executive-profile' onClick={closeMobileMenu}>Executive Profile</Link>
                        </div>
                    </div>
                    <div className='nav-link' onClick={toggleProjectsDropdown}>
                        SERVICES & PRODUCTS <FontAwesomeIcon icon={faExpand} className={`angle-icon ${projectsDropdown ? 'rotate' : ''}`} />
                        <div className={`dropdown ${projectsDropdown ? 'show' : ''}`}>
                            <Link to='/service/1' onClick={closeMobileMenu}>Custom Software Development</Link>
                            <Link to='/service/2' onClick={closeMobileMenu}>API Integration & Development</Link>
                            <Link to='/service/3' onClick={closeMobileMenu}>Advanced Data Analytics & Visualization</Link>
                            <Link to='/service/4' onClick={closeMobileMenu}>Legacy System Transformation</Link>
                            <Link to='/service/5' onClick={closeMobileMenu}>Web Design & Development</Link>
                            <Link to='/service/6' onClick={closeMobileMenu}>Technology Consultancy</Link>
                            <Link to='/products' onClick={closeMobileMenu}>Products</Link>
                        </div>
                    </div>
                    <div className='nav-link'>
                        <Link to="/case-studies" onClick={closeMobileMenu}>CASE STUDIES</Link>
                    </div>
                    <button className='contact-button' onClick={() => handleNavigation('/#contact-us-app')}><span>CONTACT US</span></button>
                    <div className='social-icons'>
                        <a href="https://www.facebook.com/byteblocktechnologies/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://x.com/byteblocktech" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                        <a href="https://www.instagram.com/byteblocktechnologies/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://linkedin.com/company/byteblock-technologies" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
                <div className='menu-text' onClick={toggleMobileMenu}>
                    {mobileMenuOpen ? <FontAwesomeIcon className='cross-icon' icon={faTimes} /> : 'Menu'}
                </div>
            </div>
        </nav>
    );
}

export default NavBar;